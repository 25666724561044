<template>
    <div>
        <Navbar :changeBgColor="true" :mdHidden="true"></Navbar>

        <div class="relative min-h-screen flex">
            <div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
                <div class="sm:w-1/2 xl:w-3/5 h-full hidden md:flex flex-auto items-center justify-center p-10 overflow-hidden bg-purple-900 text-white bg-no-repeat bg-cover relative"
                    :style="reservationCustomerSettings && reservationCustomerSettings.setting && reservationCustomerSettings.setting.welcomeSectionBackgroundImage != '' ? { backgroundImage: 'url(' + reservationCustomerSettings.setting.welcomeSectionBackgroundImage + ')' } : ''"
                    >
                    <div class="absolute bg-gradient-to-b from-indigo-600 to-blue-300 opacity-75 inset-0 z-0"></div>
                    <div class="w-full  z-10">
                        <a>
                            <span class="text-3xl font-black leading-none text-white select-none logo">
                                <img class="mx-auto" :src="reservationCustomerSettings ? reservationCustomerSettings.logo : ''" style="max-width: 150px;" />
                            </span>
                        </a>

                        <h1 v-if="reservationCustomerSettings.setting && reservationCustomerSettings.setting.welcomeSectionTitle"
                            class="mx-6 mt-4 mt-1 text-xl text-3xl font-black text-center text-white lg:text-5xl sm:text-center sm:mx-0">
                            {{ reservationCustomerSettings.setting.welcomeSectionTitle }}                  
                        </h1>
                        <h1 v-else class="mx-6 mt-4 mt-1 text-xl text-3xl font-black text-center text-white lg:text-5xl sm:text-center sm:mx-0">
                            {{ reservationCustomerSettings.companyName }}           
                        </h1>
        
                        <div v-if="reservationCustomerSettings.setting && reservationCustomerSettings.setting.welcomeSectionEtoile"
                            class="mt-3 space-x-1 text-center"
                            >
                            <div class="inline-block" v-for="index in Number(reservationCustomerSettings.setting.welcomeSectionEtoile)" :key="index">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                                </svg>
                            </div>
                        </div>
                        
                        <p v-if="reservationCustomerSettings.setting && reservationCustomerSettings.setting.welcomeSectionSubTitle" class="my-6 text-xl font-normal text-center text-gray-200">
                            {{ reservationCustomerSettings.setting.welcomeSectionSubTitle }}
                        </p>
                        <p v-else-if="reservationCustomerSettings && reservationCustomerSettings.companyAddress || reservationCustomerSettings.country" class="my-6 text-xl font-normal text-center text-gray-200">
                            <span v-if="reservationCustomerSettings.companyAddress">{{reservationCustomerSettings.companyAddress}}</span>
                            <span v-if="reservationCustomerSettings.country">{{' - ' + reservationCustomerSettings.country}}</span>
                        </p>
                    </div>

                    <!---remove custom style-->
                    <ul class="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                                    
                <div v-if="currentClientEmail != ''" class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full w-2/5 xl:w-2/5 p-8 md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white">
                    <div class="w-full space-y-8">

                        <StepsBar step="2" style="margin-top: 3.5em;"></StepsBar>

                        <div class="flex justify-end cursor-pointer">
                            <button class="text-gray-600 mr-1"  @click="clientLogOut()">{{ $t('Logout') }}</button>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                            </svg>
                        </div>
                        
                        <div v-show="!step1" class="text-center">
                            <h2 class="mt-6 text-3xl font-bold text-gray-900">
                                Bonjour {{ currentClientName }}, Vous êtes connecté
                            </h2>
                        </div>
                        
                        <div class="mt-8 space-y-6">

                            <div class="flex -mx-3">
                                <div class="w-1/2 px-3">
                                    <label for="firstname" class="text-xs font-semibold px-1">{{ $t('Firstname') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('prenom')">*</span>
                                    </label>

                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="firstname"
                                            type="text"
                                            v-model="currentClientName"
                                            placeholder="Jean"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('prenom') }"
                                            :data-vv-as="$t('Firstname')"
                                            disabled="true"
                                        >
                                    </div>

                                    <span class="text-danger text-sm">{{ errors.first('firstname') }}</span>
                                </div>
                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Lastname') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('nom')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="lastname"
                                            type="text"
                                            placeholder="Léo"
                                            v-model="currentClientLastname"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('nom') }"
                                            :data-vv-as="$t('Lastname')"
                                            disabled="true"
                                        >
                                    </div>

                                    <span class="text-danger text-sm">{{ errors.first('lastname') }}</span>
                                </div>
                            </div>

                            <div class="flex -mx-3">
                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Email') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('email')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="email"
                                            type="email"
                                            placeholder="votre@email.fr"
                                            v-model="currentClientEmail"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('email'), email: true }"
                                            :data-vv-as="$t('Email')"
                                            disabled="true"
                                        >
                                    </div>
                                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                                </div>
                                
                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('MobileNumber') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('numeroTelephone')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-phone text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="phone"
                                            v-model="phone"
                                            type="phone"
                                            placeholder="123 456 789"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('numeroTelephone') }"
                                            :data-vv-as="$t('MobileNumber')"
                                            disabled="true"
                                        >
                                    </div>
                                    <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
                                </div>

                            </div>
                               
                            <router-link :to="{ name: 'reservation-client-payment', params: { slug: $route.params.slug }}" class="flex justify-center items-center px-6 py-3 border border-transparent rounded-full shadow-sm text-sm font-semibold text-white bg-primary hover:opacity-75" rel="noopener noreferrer">
                                {{ $t('Next') }}
                                <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </router-link>

                            <router-link :to="`/${this.$route.params.slug}/welcomepage`" class="flex font-semibold text-gray-700 hover:text-gray-500 text-sm mt-10" rel="noopener noreferrer">
                                <svg class="fill-current mr-2 text-gray-600 w-4" viewBox="0 0 448 512"><path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"/></svg>
                                {{ $t('ContinueShopping') }}
                            </router-link>

                        </div>
                    </div>
                </div>

                <div v-else class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full w-2/5 xl:w-2/5 p-8  md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white">
                    <div class="max-w-md w-full space-y-8 pt-mobile">
                        <div v-show="!step1" class="text-center">
                            <h2 class="mt-6 text-3xl font-bold text-gray-900">
                                Créer votre compte
                            </h2>
                        </div>

                        <div v-show="!step1" class="flex flex-row justify-center items-center space-x-3">
                            <a @click="AuthProvider('facebook')" title="se connecter avec facebook" class="w-11 h-11 items-center justify-center inline-flex rounded-2xl font-bold text-lg bg-blue-900 hover:shadow-lg cursor-pointer transition ease-in duration-300">
                                <img class="w-4 h-4" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGQ9Im0xNS45OTcgMy45ODVoMi4xOTF2LTMuODE2Yy0uMzc4LS4wNTItMS42NzgtLjE2OS0zLjE5Mi0uMTY5LTMuMTU5IDAtNS4zMjMgMS45ODctNS4zMjMgNS42Mzl2My4zNjFoLTMuNDg2djQuMjY2aDMuNDg2djEwLjczNGg0LjI3NHYtMTAuNzMzaDMuMzQ1bC41MzEtNC4yNjZoLTMuODc3di0yLjkzOWMuMDAxLTEuMjMzLjMzMy0yLjA3NyAyLjA1MS0yLjA3N3oiIGZpbGw9IiNmZmZmZmYiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiIGNsYXNzPSIiPjwvcGF0aD48L2c+PC9zdmc+">
                            </a>
                            <a @click="AuthProvider('google')" title="se connecter avec google" class="w-11 h-11 items-center justify-center inline-flex rounded-2xl font-bold text-lg shadow-md hover:bg-gray-10 hover:shadow-lg cursor-pointer transition ease-in duration-300">
                                <svg class="h-5 w-5" viewBox="0 0 40 40">
                                    <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
                                    <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00"/>
                                    <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
                                    <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
                                </svg>
                            </a>
                        </div>

                        <div v-show="!step1" class="flex items-center justify-center space-x-2">
                            <span class="h-px w-16 bg-gray-200"></span>
                            <span class="text-gray-300 font-normal">Ou continuer avec</span>
                            <span class="h-px w-16 bg-gray-200"></span>
                        </div>
                        
                        <div class="mt-8 space-y-6">

                            <div v-show="!step1" class="flex -mx-3">
                                <div class="w-1/2 px-3">
                                    <label for="firstname" class="text-xs font-semibold px-1">{{ $t('Firstname') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('prenom')">*</span>
                                    </label>

                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="firstname"
                                            type="text"
                                            v-model="firstname"
                                            placeholder="Jean"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('prenom') }"
                                            :data-vv-as="$t('Firstname')"
                                        >
                                    </div>

                                    <span class="text-danger text-sm">{{ errors.first('firstname') }}</span>
                                </div>
                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Lastname') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('nom')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="lastname"
                                            type="text"
                                            placeholder="Léo"
                                            v-model="lastname"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('nom') }"
                                            :data-vv-as="$t('Lastname')"
                                        >
                                    </div>

                                    <span class="text-danger text-sm">{{ errors.first('lastname') }}</span>
                                </div>
                            </div>

                            <div v-show="!step1" class="flex -mx-3">
                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Email') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('email')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="email"
                                            type="email"
                                            placeholder="votre@email.fr"
                                            v-model="email"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('email'), email: true }"
                                            :data-vv-as="$t('Email')"
                                        >
                                    </div>
                                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                                </div>

                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('MobileNumber') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('numeroTelephone')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-phone text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="phone"
                                            v-model="phone"
                                            type="phone"
                                            placeholder="123 456 789"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('numeroTelephone') }"
                                            :data-vv-as="$t('MobileNumber')"
                                        >
                                    </div>
                                    <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
                                </div>

                            </div>

                            <div @click="step1=!step1" class="flex justify-start cursor-pointer">
                                <svg v-show="step1" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                                </svg>
                                                                
                                <span v-show="step1" class="text-gray-600">Précédent</span>
                            </div>

                            <div v-show="step1" class="flex -mx-3">
                                <div class="w-full px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Address') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('address')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"></div>
                                        <textarea
                                            class="w-full -ml-10 pl-2 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="address"
                                            v-model="address" 
                                            :placeholder="$t('Address')"
                                            cols="10"
                                            rows="5"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('address') }"
                                            :data-vv-as="$t('Address')"
                                        ></textarea>
                                    </div>
                                </div>
                                <span class="text-danger text-sm">{{ errors.first('address') }}</span>
                            </div>
                            
                            <div v-show="step1"  class="flex -mx-3">
                                <div class="w-1/3 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('ZipCode') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('codePostal')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-numeric text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="postal_code"
                                            v-model="postal_code"
                                            type="text"
                                            placeholder="75000"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('codePostal') }"
                                            :data-vv-as="$t('ZipCode')"
                                        >
                                    </div>
                                </div>
                                <div class="w-1/3 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Country') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('pays')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-google-maps text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="country"
                                            v-model="country"
                                            type="text"
                                            placeholder="75000"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('country') }"
                                            :data-vv-as="$t('Country')"
                                        >
                                    </div>
                                </div>
                                <div class="w-1/3 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('City') }}
                                        <span class="text-red-500" v-if="showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('ville')">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-map-marker-radius text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            name="city"
                                            v-model="city"
                                            type="text"
                                            placeholder="Paris"
                                            v-validate="{ required: showFields(reservationCustomerSettings) && reservationCustomerSettings.config.fields.includes('ville') }"
                                            :data-vv-as="$t('City')"
                                        >
                                    </div>
                                </div>

                            </div>

                            <div>
                                <span class="text-danger text-sm">{{ errors.first('postal_code') }}</span>
                            </div>

                            <div>
                                <span class="text-danger text-sm">{{ errors.first('country') }}</span>
                            </div>

                            <div>
                                <span class="text-danger text-sm">{{ errors.first('ville') }}</span>
                            </div>

                            <div v-show="step1" class="flex -mx-3">
                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Password') }}
                                        <span class="text-red-500">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            type="password"
                                            autocomplete="new-password"
                                            placeholder="************"
                                            name="password"
                                            v-model="password"
                                            v-validate="'required'"
                                            :data-vv-as="$t('Password')"
                                        >
                                    </div>
                                </div>
                                <div class="w-1/2 px-3">
                                    <label for="" class="text-xs font-semibold px-1">{{ $t('Password_confirmation') }}
                                        <span class="text-red-500">*</span>
                                    </label>
                                    <div class="flex">
                                        <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                                        <input
                                            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            type="password"
                                            autocomplete="new-password"
                                            placeholder="************"
                                            name="password_confirmation"
                                            v-model="password_confirmation"
                                            v-validate="'required'"
                                            :data-vv-as="$t('Password_confirmation')"
                                        >
                                    </div>
                                </div>
                            </div>

                            <span>
                                <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                            </span>

                            <span>
                                <span class="text-danger text-sm">{{ errors.first('password_confirmation') }}</span>
                            </span>
                                
                            <vs-button 
                                v-show="!step1"
                                class="w-full flex justify-center rounded-full p-3 hover:opacity-75 tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
                                @click="step1=!step1"
                            >
                                Tapez votre mot de passe
                                <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </vs-button>
                            
                            <!-- <div class="flex items-center justify-between">
                                <div class="flex items-center">
                                    <input id="remember_me" name="remember_me" type="checkbox"
                                        class="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded">
                                    <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                                    Remember me
                                    </label>
                                </div>
                                <div class="text-sm">
                                    <a href="#" class="text-indigo-400 hover:text-blue-500">
                                    Forgot your password?
                                    </a>
                                </div>
                            </div> -->

                            
                        <div v-show="step1" class="terms-condition text-gray-700">
                            <vs-checkbox 
                                v-model="isTermsConditionAccepted" 
                                name="terms_condition" 
                                class="mb-2"
                                v-validate="{ required: true }"
                            >J'accepte les termes et conditions.</vs-checkbox>

                            <span class="text-danger text-sm">{{ errors.first('terms_condition') }}</span>
                            
                            <span class="text-gray-500 text-sm">En acceptant, j'accepte que les informations de ce formulaire soient utilisées pour me recontacter ou recevoir des informations ponctuelles relatives à ma commande.</span>
                        </div>

                        <vs-button 
                            v-show="step1"
                            class="w-full flex justify-center rounded-full p-3 tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
                            ref="loadableButton"
                            @click="validateFormRegister"
                            :disabled="!FormRegisterIsValid" 
                        >
                            {{ $t('Register') }}
                            
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                            </svg>
                        </vs-button>

                        <p class="flex flex-col items-center justify-center mt-10 text-center text-md text-gray-500">
                            <span class="text-primary font-medium">Vous avez déjà un compte ?</span>
                            <a @click="toggleDataIsPoupupAddAndEditActive(true)" class="text-indigo-700 text-lg hover:text-blue-800 no-underline hover:underline cursor-pointer transition ease-in duration-300">
                                Login
                                <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                                </svg>
                            </a>
                        </p>

                        <p class="flex flex-col items-center justify-center mt-10 text-center text-md text-gray-500">
                            <a @click="toggleResetPasswordPoupUp(true)" class="text-indigo-700 text-sm hover:text-blue-800 no-underline hover:underline cursor-pointer transition ease-in duration-300">
                              Mot de passe oublié ?
                            </a>
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PopupLogin
            :customerSettingsListFields="reservationCustomerSettings.config && showFields(reservationCustomerSettings) ? reservationCustomerSettings.config.fields : ''"
            :isPopupActive="showProductCartPopupActive"
            @closeSidebar="toggleDataIsPoupupAddAndEditActive"
        />

        <PoupupResetPassword
            :isPopupActive="showResetPasswordPoupUp"
            @closeSidebar="toggleResetPasswordPoupUp"
        />
    </div>
</template>

<script>

import { Validator } from 'vee-validate';
import { mapState, mapGetters } from "vuex";
const PopupLogin = () => import("@/views/welcome-page/PopupLogin.vue");
const PoupupResetPassword = () => import("@/views/welcome-page/PoupupResetPassword.vue");
const StepsBar = () => import("@/views/welcome-page/StepsBar.vue");
const Navbar = () => import("@/views/welcome-page/Navbar.vue");

const dictionary = {
  en: {
    firstname: {
      required: 'required'
    }
  },
  fr: {
    firstname: {
      required: 'obligatoire'
    }
  }
};

Validator.localize(dictionary)
Validator.localize('fr')

import moduleSettingManagement from '@/store/settings/moduleSettingManagement.js'

export default {
    data () {
    return {
      step1: false,
      popupLogin: false,
      popupRecoverPassword: false,
      showProductCartPopupActive: false,
      showResetPasswordPoupUp: false,

      email: '',
      loginEmail: '',
      password: '',
      loginPassword: '',
      checkbox_remember_me: false,

      firstname: '',
      lastname: '',
      phone: '',
      postal_code: '',
      address: '',
      country:'',
      city: '',
      num_chambre: '',
      password_confirmation: '',
      isTermsConditionAccepted: true,
      
    }
  },
  computed: {
    reservationCustomerSettings() {
        return this.$store.state.moduleSetting.customerSetting
    },
    validateFormLogin () {
      return this.loginEmail !== '' && this.loginPassword !== ''
    },
    isClientLoggedIn () {
      return this.$store.state.auth.isClientLoggedIn()
    },
    ...mapState({
      mapIsClientLoggedIn: state => state.auth.isClientLoggedIn(),
    }),
    currentClientName () {
      return this.currentClient?this.currentClient.nom :''
    },
    currentClientLastname () {
      return  this.currentClient?this.currentClient.prenom :''
    //   return this.$store.getters['getActiveClientForCurrentCompany'] != null ? this.$store.getters['getActiveClientForCurrentCompany'].prenom : ''
    },
    currentClientEmail () {
      return this.currentClient?this.currentClient.email:''
    //   return this.$store.getters['getActiveClientForCurrentCompany'] != null ? this.$store.getters['getActiveClientForCurrentCompany'].email : ''
    },
    currentClient () {
      return this.$store.state.AppActiveClient.find((c) => c.company_uuid === this.$store.state.company.uuid)
    //   return this.$store.getters['getActiveClientForCurrentCompany'] != null ? this.$store.getters['getActiveClientForCurrentCompany'].email : ''
    },
    FormRegisterIsValid () {
      return this.firstname !== '' && this.lastname !== '' && this.email !== '' && this.password !== '' && this.password_confirmation !== '' && this.isTermsConditionAccepted === true
    },
  },
  methods: {
    showFields(customerSettings) {
        return customerSettings && customerSettings.config ? true : false
    },
    clientLogOut() {
      console.log('clientLogOut' )
      this.cleanClientData()
      this.$validator.reset();
      this.$store.dispatch('auth/clientLogOut')
    },
    isClientLoggedInFn () {
      return this.$store.state.auth.isClientLoggedIn()
    },
    openPopupLogin () {
      this.loginPassword = '';
      this.loginEmail = '';
      this.popupLogin = true
    },
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Tentative de connexion',
          text: 'Vous êtes déjà connecté!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    validateFormRegister () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitRegisterClient()
        } 
        return false
      })
    },
    AuthProvider(provider) {
      this.$vs.loading()
      
      var self = this

      this.$auth.authenticate(provider).then(response => {

      self.SocialLogin(provider,response)

      }).catch(err => {
          this.$vs.loading.close()
          this.$vs.loading.close('#popup-login > .con-vs-loading')
          console.log({err:err})
      })
    },

    SocialLogin(provider, response) {
      response.provider = provider
      response.config = this.$auth.options.providers[provider].redirectUri;
      const payload = {
        provider: provider,
        response: response,
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }

      console.log('payload SocialLogin', payload)

      this.$store.dispatch('auth/SocialLogin', payload)
    },
    
    loginClient () {
      // If form is not validated or user is already login return
      if (!this.validateFormLogin || !this.checkLogin()) return

      this.$vs.loading()

      const payload = {
        userDetails: {
          email: this.loginEmail,
          password: this.loginPassword
        },
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      
      this.$store.dispatch('auth/loginClient', payload)
    },
    
    async submitRegisterClient () {
      console.log('submitRegisterClient')
      // If form is not validated or user is already login return
      // if (!this.validateForm || !this.checkLogin()) return
      // if (!this.validateFormRegister) return
      this.$vs.loading()

      const payload = {
        userDetails: {
          fromAddClient: "addclient",
          nom: this.firstname,
          prenom: this.lastname,
          email: this.email,
          cartebancaire: "",
          datedenaissance: "",
          numerochambre: "",
          password: this.password,
          password_confirmation: this.password_confirmation,
          telephone: this.phone,
          codepostal: this.postal_code,
          address: this.address,
          pays: this.country,
          ville: this.city,
          lang: this.$i18n.locale,
        },
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }

      await this.$store.dispatch('auth/registerClient', payload)
      .then(res => {
        console.log('res', res)
        this.$router.push({ name: 'reservation-client-payment', params: { slug: this.$route.params.slug } })
      })
      .catch(err => { 
        console.error(err)
      })
    },

    // Google login
    loginWithGoogle () {
      this.$store.dispatch('auth/loginWithGoogle', { notify: this.$vs.notify })
    },

    // Facebook login
    loginWithFacebook () {
      this.$store.dispatch('auth/loginWithFacebook', { notify: this.$vs.notify })
    },

    // Twitter login
    loginWithTwitter () {
      this.$store.dispatch('auth/loginWithTwitter', { notify: this.$vs.notify })
    },

    // Github login
    loginWithGithub () {
      this.$store.dispatch('auth/loginWithGithub', { notify: this.$vs.notify })
    },
    cleanClientData() {
      this.email = '',
      this.firstname = '',
      this.lastname = '',
      this.phone = '',
      this.postal_code = '',
      this.address = '',
      this.country = '',
      this.city = '',
      this.num_chambre = '',
      this.password = '',
      this.password_confirmation = '',
      this.checkbox_remember_me = false
    },
    toggleDataIsPoupupAddAndEditActive (val = false) {
      this.showProductCartPopupActive = val
    },

    toggleResetPasswordPoupUp (val = false) {
      this.showResetPasswordPoupUp = val
    },
  },

    components: {
        PoupupResetPassword,
        PopupLogin,
        StepsBar,
        Navbar
    },
    mounted() {
        this.$store.commit('eCommerce/UPDATE_WIZARD_STEPS_BAR', 2)
    },
    created () {
        if (!moduleSettingManagement.isRegistered) {
        this.$store.registerModule('settingManagement', moduleSettingManagement)
        moduleSettingManagement.isRegistered = true
        }
    }

}
</script>

<style lang="css">

@import "https://unpkg.com/tailwindcss@2.0.2/dist/tailwind.min.css";

/*remove custom style*/
.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;  
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}
 
.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}
 
.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}
 
.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}
 
.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}
 
.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}
 
.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}
 
.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}
 
.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}
 
.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}
  @keyframes animate {
 
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
 
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
 
}

@media (max-width: 600px) { 
    .pt-mobile{
        padding-top: 3.5em;
    }
}


</style>